import { getSplitClient } from './split-client'

/**
 * Returns an promise that resolves a boolean for successful or not.  If not successful, use debugging tips here: https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#logging
 * @param {string} trafficType  'tenant' or 'hostname'
 * @param {string} eventType  name of the event
 * @param {number} [value]  value
 * @param {Object} [properties]  properties
 * @returns {Promise<boolean>}
 */
export async function trackEvent(trafficType, eventType, value, properties) {
  const client = await getSplitClient()

  return client.track(trafficType, eventType, value, properties)
}
