export default function splitFactoryConfig(apiKey, tenantID, splitSDKSettings) {
  const { core: coreSettings, ...restSettings } = splitSDKSettings
  return {
    startup: {
      readyTimeout: 3,
    },
    core: {
      ...coreSettings,
      authorizationKey: apiKey,
      key: tenantID,
    },
    ...restSettings,
  }
}
