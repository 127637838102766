import { firstValueFrom } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { treatmentsWithOverrides$ } from './internal-state'

/**
 * Returns an Observable with the treatment's configuration object or null.
 * @param {string} split  split name
 * @param {boolean} [parse]  controls whether or not the configuration is preparsed
 * @returns {import('rxjs').Observable<any>}
 */
export function getConfiguration$(split, parse = true) {
  return treatmentsWithOverrides$.pipe(
    map((allTreatments) => {
      const config = allTreatments[split]?.config
      if (config) return parse ? JSON.parse(config) : config
      return null
    }),
  )
}

/**
 * Returns a Promise with the treatment's configuration object or null.
 * @param {string} split  split name
 * @param {boolean} [parse]  controls whether or not the configuration is preparsed
 * @returns {Promise<any>}
 */
export function getConfiguration(split, parse = true) {
  return makeThenable(getConfiguration$(split, parse), 'getConfiguration')
}

/**
 * Returns an Observable with all treatments with overrides applied.
 * @param {Object} [config]
 * @returns {import('rxjs').Observable<string>}
 */
export function getAllTreatments$() {
  return treatmentsWithOverrides$
}

/**
 * Returns a Promise with all treatments with overrides applied.
 * @param {Object} [config]
 * @returns {Promise<string>}
 */
export function getAllTreatments() {
  return makeThenable(getAllTreatments$(), 'getAllTreatments')
}

// 10s arbitrarily chosen
const DELAYED_SPLIT_CLIENT_IMPRESSION_TIME = 10e3
/**
 * Returns an Observable with the current value of the treatment,
 * or null if it does not exist.
 * @param {string} split  split name
 * @returns {import('rxjs').Observable<string>}
 */
export function getTreatment$(split) {
  if (window.splitKey) {
    setTimeout(() => {
      import('./split-client/logImpression.js').then(({ logImpression }) => {
        logImpression(split)
      })
    }, DELAYED_SPLIT_CLIENT_IMPRESSION_TIME)
  }

  return getAllTreatments$().pipe(
    map((allTreatments) => allTreatments[split]?.treatment || null),
  )
}

/**
 * Returns a Promise with the current value of the treatment,
 * or null if it does not exist.
 * @param {string} split  split name
 * @returns {Promise<string>}
 */
export function getTreatment(split) {
  return makeThenable(getTreatment$(split), 'getTreatment')
}

/**
 * Returns an Observable
 * with a boolean value representing whether that treatment is exactly "on".
 * @param {string} split  split name
 * @returns {import('rxjs').Observable<boolean>}
 */
export function getTreatmentIsEnabled$(split) {
  return getTreatment$(split).pipe(map((treatment) => treatment === 'on'))
}

/**
 * Returns a Promise
 * with a boolean value representing whether that treatment is exactly "on".
 * @param {string} split  split name
 * @returns {Promise<boolean>}
 */
export function getTreatmentIsEnabled(split) {
  return makeThenable(getTreatmentIsEnabled$(split), 'getTreatmentIsEnabled')
}

function makeThenable(observable, methodName) {
  const promise = firstValueFrom(observable)

  // The rest of this function is all for backwards compatibility.  When toggles-mfe-promise-deprecation-warning is removed because we see in DataDog that no more usages exist, then we can remove the rest of this function.
  const newObservable = warnOnDeprecatedUsage(observable, methodName)

  // biome-ignore lint/suspicious/noThenProperty: This is for backwards compatibility so ignoring this rule to prevent tests breaking
  newObservable.then = (...args) => promise.then(...args)
  newObservable.catch = (...args) => promise.catch(...args)

  return newObservable
}

let shouldWarnOfDeprecation = false
getTreatmentIsEnabled$('toggles-mfe-promise-deprecation-warning').subscribe(
  (value) => {
    shouldWarnOfDeprecation = value
  },
)

function warnOnDeprecatedUsage(observable, methodName) {
  return observable.pipe(
    tap(() => {
      if (shouldWarnOfDeprecation) {
        console.warn(
          `[Deprecation from @wf-mfe/toggles] You are using ${methodName}() as an observable. This is deprecated and will be removed in a future release. Either use this method as a promise, or if you want to use the observable version, use ${methodName}$() instead.`,
        )
      }

      if (window.DD_RUM) {
        window.DD_RUM.addAction('toggleUtilityUsedAsObservable', {
          path: window.location.href,
          methodName,
        })
      }
    }),
  )
}
