import { initializeSplitClient } from './initialize-split-client.js'

let client

export async function getSplitClient() {
  if (client === undefined) {
    client = await initializeSplitClient(window.splitKey) // eslint-disable-line require-atomic-updates
  }

  return client
}
