import { useState, useEffect } from 'react'

import {
  getTreatment$,
  getConfiguration$,
  getAllTreatments$,
} from './utilities'

// TODO: After completing RxJS 6 -> 7 migration come back here and look at using a Behavior Subject instead so we don't have to subscribe internally.
let treatments = {}

getAllTreatments$().subscribe((t) => {
  treatments = t
})

/**
 * Returns the current treatment for a split.
 * Returns null if Split has no value for that treatment.
 * @param {string} split  split name
 * @returns {string | undefined}
 */
export function useTreatment(split) {
  const [treatment, setTreatment] = useState(treatments?.[split]?.treatment)
  useEffect(() => {
    const subscription = getTreatment$(split).subscribe(setTreatment)
    return () => subscription.unsubscribe()
  }, [split])
  return treatment
}

/**
 * Returns a boolean value representing whether that treatment
 * is the compareTo value, which is 'on' by default
 * @param {string} split  split name
 * @param {string} [compareTo]  a value to compare split against
 * @returns {boolean}
 */
export function useIsTreatmentEnabled(split, compareTo = 'on') {
  const treatmentValue = useTreatment(split)
  return treatmentValue === compareTo
}

/**
 * Returns the treatment's configuration object or null
 * @param {string} split  split name
 * @returns {Object | undefined}
 */
export function useTreatmentConfig(split) {
  const [config, setConfig] = useState(treatments?.[split]?.config)
  useEffect(() => {
    const subscription = getConfiguration$(split).subscribe(setConfig)
    return () => subscription.unsubscribe()
  }, [split])
  return config
}
