import { ReplaySubject, fromEvent, combineLatest } from 'rxjs'
import { map, startWith, shareReplay } from 'rxjs/operators'

const _treatmentsFromSplit$ = new ReplaySubject(1)

// Everytime a split is change from the workfront-inspector we need to know
const splitChangedEvent$ = fromEvent(window, 'wf:toggle-state-changed').pipe(
  /* combineLatest requires each stream to have emited at least once this forces this stream to have a "first" emission */
  startWith([]),
)

const unknownTreatmentOptions = ['on', 'off']

/*
 * In order to make changes the toggles "live" we want to compute
 * and emit results from this stream in the following scenarios:
 *
 * 1. Whenever the user changes (treatments$)
 * 2. Whenever the customer changes (treatment$)
 * 3. Whenever a toggle is overridden (splitChangeEvent$)
 */
export const treatmentsWithOverrides$ = combineLatest([
  _treatmentsFromSplit$.asObservable(),
  splitChangedEvent$,
]).pipe(
  map(([treatmentsFromSplit]) => {
    const overriddenTreatments = getOverriddenToggles()
    return combineSplitsAndOverrides(treatmentsFromSplit, overriddenTreatments)
  }),
  shareReplay(1),
)

function getOverriddenToggles() {
  const toggleOverridePrefix = 'toggle-override:'
  const overriddenTreatments = Object.entries(localStorage).reduce(
    (acc, [localStorageKey, treatmentOverride]) => {
      if (localStorageKey.indexOf(toggleOverridePrefix) === 0) {
        const toggleKey = localStorageKey.replace(toggleOverridePrefix, '')
        acc[toggleKey] = {
          treatment: treatmentOverride,
        }
      }
      return acc
    },
    {},
  )
  return overriddenTreatments
}

function combineSplitsAndOverrides(splits, overrides) {
  return Object.entries({
    ...splits,
    ...overrides,
  }).reduce(
    (
      acc,
      [treatmentKey, { treatmentOptions = unknownTreatmentOptions, ...rest }],
    ) => {
      acc[treatmentKey] = {
        ...rest,
        overridden: !!overrides[treatmentKey],
        ...{ treatmentOptions },
      }
      return acc
    },
    {},
  )
}

export function updateTreatmentsFromSplit(treatmentsFromSplit) {
  _treatmentsFromSplit$.next(treatmentsFromSplit)
}

export const __testing_only__ = {
  combineSplitsAndOverrides,
  getOverriddenToggles,
}
