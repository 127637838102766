const QUICKSILVER_SERVICE = 'service-quicksilver'

export const serviceAttributes = {
  hostname: window.location.hostname || 'localhost',
  serviceID: QUICKSILVER_SERVICE,
  currentTime: Date.now(),
  ...getCloudAndGeo(),
}

function getCloudAndGeo() {
  const NULL_CLOUD_AND_GEO = {}

  if (!window.config) {
    return NULL_CLOUD_AND_GEO
  }

  const region =
    window.config.QUICKSILVER_REGION || window.config.CURRENT_REGION

  if (!region || !region.includes('-')) {
    return NULL_CLOUD_AND_GEO
  }

  const parts = region.toUpperCase().split('-')

  const [cloud, serviceGeo] = parts

  if (parts.length !== 2 || cloud.length < 3 || serviceGeo.length < 2) {
    return NULL_CLOUD_AND_GEO
  }

  return {
    cloud,
    serviceGeo,
  }
}
